body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'haylard-text', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a.menuLink{
  color: white;
  text-decoration: none;
}
a.linkBlue{
  color: #2ab1ea;
  text-decoration: none;
}
a.linkBlue:hover{
  color: #2ab1ea;
}
a{
  text-decoration: none!important;
}
